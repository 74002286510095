import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import SalesManagement from "../../customComponents/salesManagementy"
import "./style.scss"

const index = () => {
  return (
    <div className="sales-management-page">
      <Topbar />
      <CustomLayout
        selectedPage={
          // <SaleManagementProvider>
          <SalesManagement />
          // </SaleManagementProvider>
        }
      />
    </div>
  )
}

export default index
