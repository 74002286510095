import { Button, Space, Table } from "antd"
import React, { useContext } from "react"
import _ from "lodash"
import moment from "moment"
import { MySalesManagementContext } from "../index.jsx"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { navigate } from "gatsby"

const fulfillmentStatusMap = {
  not_fulfilled: {
    value: "not_fulfilled",
    display: "満たされていない",
    keyTab: "3",
  },
  partially_fulfilled: {
    value: "partially_fulfilled",
    display: "部分的に満たされた",
    keyTab: "3",
  },
  fulfilled: {
    value: "fulfilled",
    display: "満たされた",
    keyTab: "7",
  },
  partially_shipped: {
    value: "partially_shipped",
    display: "一部出荷",
    keyTab: "4",
  },
  shipped: {
    value: "shipped",
    display: "出荷された",
    keyTab: "4",
  },
  partially_returned: {
    value: "partially_returned",
    display: "一部返却",
    keyTab: "6",
  },
  returned: {
    value: "returned",
    display: "戻ってきた",
    keyTab: "6",
  },
  canceled: {
    value: "canceled",
    display: "キャンセル処理",
    keyTab: "5",
  },
  requires_action: {
    value: "requires_action",
    display: "アクションが必要です",
    keyTab: "2",
  },
}

const japaneseDateConvert = (string) => {
  const date = moment(string)
  return date.format("YYYY/MM/DD HH:mm")
}

const paddingZero = (numb, size) => {
  const numbSize = _.toString(numb).length
  if (numbSize < size) {
    const leadingZero = size - numbSize
    const leadingZeroStr = new Array(leadingZero).fill(0).join("")
    return `${leadingZeroStr}${_.toString(numb)}`
  }
  if (numbSize > size) {
    return `0${numb}`
  }
}

const getShippingAddress = (order) => {
  const shippingAddress = order?.shipping_address || {}
  const address_1 = shippingAddress?.address_1 || ""
  const address_2 = shippingAddress?.address_2 || ""
  const city = shippingAddress?.city || ""
  const province = shippingAddress?.province
  const postalCode = shippingAddress.postal_code

  return `${postalCode} ${province} ${city} ${address_1} ${address_2}`
}

const DataTable = () => {
  const {
    breadcrumbMap,
    activePage,
    tableData,
    total,
    loading,
    tableParams,
    setTableParams,
  } = useContext(MySalesManagementContext)
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <>
          <Space>
            <Button style={{ borderRadius: "2px" }}>
              <a>
                <DoubleLeftOutlined
                  style={{ verticalAlign: "middle", fontSize: "1rem" }}
                />
              </a>
            </Button>
            <Button style={{ borderRadius: "2px" }}>
              <a className="font-semibold">前のページ</a>
            </Button>
          </Space>
        </>
      )
    }

    if (type === "next") {
      return (
        <>
          <Space>
            <Button style={{ borderRadius: "2px" }}>
              <a className="font-semibold">次のページ</a>
            </Button>
            <Button style={{ borderRadius: "2px" }}>
              <a>
                <DoubleRightOutlined
                  style={{ verticalAlign: "middle", fontSize: "1rem" }}
                />
              </a>
            </Button>
          </Space>
        </>
      )
    }

    return originalElement
  }
  const columns = [
    {
      title: (
        <div
          className="text-center flex flex-col"
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <span>問い合わせ番号</span>
          <span>注文番号</span>
        </div>
      ),
      width: 120,
      dataIndex: "display_id",
      key: "display_id",
      render: (displayId) => (
        <div
          style={{
            color: "#888888",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {paddingZero(displayId, 9)}
        </div>
      ),
    },
    {
      title: (
        <div
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          問合せ日時
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      width: 140,
      render: (text) => (
        <div style={{ color: "#888888", textAlign: "center" }}>{text}</div>
      ),
    },
    {
      title: (
        <div
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          ステータス
        </div>
      ),
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 130,
      render: (status) => (
        <div style={{ ...status?.style, textAlign: "center" }}>{status}</div>
      ),
    },
    // {
    //   title: (
    //     <div
    //       className="text-center"
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       対応状況
    //     </div>
    //   ),
    //   dataIndex: "fulfillmentStatus",
    //   key: "fulfillmentStatus",
    //   width: 130,
    //   render: (status) => (
    //     <div style={{ ...status?.style, textAlign: "center" }}>
    //       {status?.display}
    //     </div>
    //   ),
    // },
    {
      title: (
        <div
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          発注者
        </div>
      ),
      width: 140,
      dataIndex: "customer",
      key: "customer",
      render: (text) => (
        <div style={{ color: "#222222", textAlign: "center" }}>{text}</div>
      ),
    },
    {
      title: (
        <div
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          発注先住所
        </div>
      ),
      width: 150,
      dataIndex: "shippingAddress",
      key: "shippingAddress",
      render: (text) => (
        <div style={{ color: "#222222", textAlign: "center" }}>{text}</div>
      ),
    },
    {
      title: (
        <div
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          決済方法
        </div>
      ),
      width: 90,
      dataIndex: "payment_type",
      key: "payment_type",
      render: (text) => (
        <div style={{ color: "#222222", textAlign: "center" }}>
          {text?.toUpperCase()}
        </div>
      ),
    },
    {
      title: (
        <div
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          金額
        </div>
      ),
      width: 100,
      dataIndex: "total_price",
      key: "total_price",
      render: (total) => (
        <div style={{ color: "#222222", textAlign: "center" }}>
          {total ? `${new Intl.NumberFormat("ja-JP", {}).format(total)}円` : ""}
        </div>
      ),
    },
    {
      title: (
        <div
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          メッセージ
        </div>
      ),
      width: 300,
      dataIndex: "message",
      key: "message",
      render: (text) => (
        <div style={{ color: "#222222", textAlign: "center" }}>{text}</div>
      ),
    },
  ]

  const data = _.map(tableData, (order, idx) => {
    const payment = order.parent.payments?.[0]
    return {
      display_id: order?.display_id,
      order_id: order?.id,
      created_at: japaneseDateConvert(order.created_at),
      customer: order?.customer?.user?.metadata?.company_name,
      orderStatus: breadcrumbMap?.filter(
        (el) => el.status == order?.status
      )?.[0]?.titleDisplay,
      fulfillmentStatus: fulfillmentStatusMap[order?.fulfillment_status],
      shippingAddress: getShippingAddress(order),
      payment_type: payment?.provider_id,
      total_price: order?.total,
      message: order?.lastMessage,
    }
  })

  // console.log({ data })
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }
  return (
    <div>
      <Table
        scroll={{ x: 1900 }}
        locale={{ emptyText: "データなし" }}
        style={{ cursor: "pointer" }}
        columns={columns}
        dataSource={data}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              navigate(
                `/sales-management/detail?keyTab=${activePage}&orderId=${record?.order_id}`
              )
            },
          }
        }}
        rowKey={(data) => data.order_id}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,

          total: total,
          itemRender: itemRender,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div className="w-full">
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          ),
        }}
      />
    </div>
  )
}

export default DataTable
