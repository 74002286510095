import { Content } from "antd/lib/layout/layout"
import { Breadcrumb } from "antd"
import Title from "antd/lib/typography/Title"
import { Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import "./style.css"
import "./style.scss"
import { getOrders } from "../../services/apiProduct"
import ListDataTable from "./listDataTable"
import moment from "moment"

export const MySalesManagementContext = React.createContext()

const SalesManagement = () => {
  const [searchParams, setSearchParams] = useState(null)
  const breadcrumbMap = [
    {
      status: "all",
      titleDisplay: "すべて",
      breadcrumbDisplay: "",
    },
    {
      status: "pending",
      titleDisplay: "発注承認待ち",
      breadcrumbDisplay: "",
    },
    { status: "toShip", titleDisplay: "発送中", breadcrumbDisplay: "" },
    { status: "prepare", titleDisplay: "発送準備中", breadcrumbDisplay: "" },
    {
      status: "toReceive",
      titleDisplay: "納品完了",
      breadcrumbDisplay: "発送準備",
    },
    {
      status: "completed",
      titleDisplay: "発注処理完了",
      breadcrumbDisplay: "発送完了（納品）",
    },
    {
      status: "canceled",
      titleDisplay: "キャンセル",
      breadcrumbDisplay: "キャンセル ",
    },
    {
      status: "requires_action",
      titleDisplay: "返品・返金処理中",
      breadcrumbDisplay: "返品・返金処理中",
    },
    {
      status: "refunded",
      titleDisplay: "返品・返金処理完了",
      breadcrumbDisplay: "",
    },
  ]

  const [activePage, setActivePage] = useState("all")
  const [loading, setLoading] = useState(false)

  const [total, setTotal] = useState(0)
  const [tableData, setTableData] = useState(null)
  const [countStatus, setCountStatus] = useState(null)

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })
  const handleGetListData = async () => {
    setLoading(true)
    const dataSent = {
      "created_at[gt]": searchParams?.startDate
        ? moment(searchParams?.startDate).format("YYYY-MM-DD")
        : null,
      "created_at[lt]": searchParams?.endDate
        ? moment(searchParams?.endDate).format("YYYY-MM-DD")
        : null,
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      status: activePage == "all" ? null : activePage,
      q: searchParams?.keyword,
    }

    const { response, data } = await getOrders({ params: dataSent })
    console.log("responseresponseresponse", response)
    if (response.status == 200) {
      setTableData(response?.data?.orders)
      setTotal(response?.data?.count)
      // setStatusCount({
      //   ...response.data?.data?.statusCount,
      //   count: response.data?.data?.count,
      // });
      setCountStatus(response?.data?.counter)

      setLoading(false)
    }
  }
  useEffect(() => {
    if (tableData) {
      handleGetListData()
    }
  }, [tableParams])

  useEffect(() => {
    handleGetListData()
  }, [])

  useEffect(() => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    })
  }, [activePage, searchParams])

  const data = {
    breadcrumbMap,
    activePage,
    setActivePage,
    tableData,
    total,
    tableParams,
    loading,
    setTableParams,
    searchParams,
    setSearchParams,
    countStatus,
  }

  return (
    <MySalesManagementContext.Provider value={data}>
      <Content>
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item route="/sales-management">
              <span style={{ cursor: "pointer" }}>販売管理</span>
            </Breadcrumb.Item>
            {breadcrumbMap
              ?.filter((el) => el.status == activePage)
              ?.map((ele, index) => {
                return (
                  <Breadcrumb.Item route="/sales-management" key={index}>
                    {ele.titleDisplay}
                  </Breadcrumb.Item>
                )
              })}
          </Breadcrumb>
          <Title
            level={3}
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              padding: "20px 25px",
            }}
          >
            {`受注管理-${
              breadcrumbMap?.filter((el) => el.status == activePage)?.[0]
                ?.titleDisplay
            }`}
          </Title>
          <ListDataTable />
        </div>
      </Content>
    </MySalesManagementContext.Provider>
  )
}

export default SalesManagement
