import { Space, Tabs } from "antd"
import React, { useContext } from "react"
import { MySalesManagementContext } from ".."

const { TabPane } = Tabs

const ActiveTabsPage = () => {
  const { activePage, setActivePage, breadcrumbMap, countStatus } = useContext(
    MySalesManagementContext
  )
  const onChange = (key) => {
    setActivePage(key)
  }
  return (
    <Tabs
      activeKey={activePage}
      onChange={onChange}
      size="large"
      className="border"
      tabBarStyle={{
        paddingLeft: 20,
      }}
    >
      <TabPane
        tab={
          <Space>
            <div>{"すべて"}</div>
          </Space>
        }
        key="all"
      ></TabPane>
      <TabPane
        tab={
          <Space>
            <div>{"発注承認待ち"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countPending}
            </div>
          </Space>
        }
        key="pending"
      ></TabPane>
      <TabPane
        tab={
          <Space>
            <div>{"発送準備中"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countPrepare}
            </div>
          </Space>
        }
        key="prepare"
      ></TabPane>
      <TabPane
        tab={
          <Space>
            <div>{"発送中"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countToShip}
            </div>
          </Space>
        }
        key="toShip"
      ></TabPane>

      <TabPane
        tab={
          <Space>
            <div>{"納品完了"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countToReceive}
            </div>
          </Space>
        }
        key="toReceive"
      ></TabPane>
      <TabPane
        tab={
          <Space>
            <div>{"発注処理完了"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countCompleted}
            </div>
          </Space>
        }
        key="completed"
      ></TabPane>
      <TabPane
        tab={
          <Space>
            <div>{"キャンセル"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countCanceled}
            </div>
          </Space>
        }
        key="canceled"
      ></TabPane>
      <TabPane
        tab={
          <Space>
            <div>{"返品・返金処理中"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countRequiresAction}
            </div>
          </Space>
        }
        key="requires_action"
      ></TabPane>
      <TabPane
        tab={
          <Space>
            <div>{"返品・返金処理完了"}</div>
            <div
              style={{
                borderRadius: "50%",
                background: "#FDEBEE",
                padding: 5,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {countStatus?.countRefunded}
            </div>
          </Space>
        }
        key="refunded"
      ></TabPane>
    </Tabs>
  )
}

export default ActiveTabsPage
